<template>
  <AllerVideoJWP #default="slotProps" :player-id="related && config.jwPlayer.playerIds.watchThis ? config.jwPlayer.playerIds.watchThis : playerId" :setup-override="setupOverride" :media="media" @playlist-loaded="emitLoaded">
    <div v-cloak :class="{ 'bg-white pt-5 jw-aller-related': related }">
      <div v-show="slotProps.marketingConsent" :class="{ 'bg-gray-100 p-5': related }">
        <h2 v-if="related && slotProps.relatedTitle" class="pt-[2px] pb-[6px] flex pr-10 items-center">
          <span class="font-black uppercase text-24 whitespace-nowrap">Se også</span>
          <span class="font-normal text-12 leading-14 line-clamp-2 ml-5 border-l pl-5 border-black" v-html="slotProps.relatedTitle"></span>
        </h2>
        <div class="pb-[56.25%] relative">
          <div class="absolute top-0 left-0 right-0">
            <div :id="slotProps.jwPlayerId"></div>
          </div>
        </div>
        <div v-if="related && slotProps.relatedCredits" class="font-light text-11 leading-14 text-right pt-4">{{ slotProps.relatedCredits }}</div>
      </div>
      <LazyConsentPrompt v-show="!slotProps.marketingConsent" />
    </div>
  </AllerVideoJWP>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script setup lang="ts">
defineProps({
  playerId: {
    type: String,
    required: false,
  },
  setupOverride: {
    type: Object,
    required: false,
  },
  media: {
    type: Object,
    required: true,
  },
  related: {
    type: Boolean,
    default: false,
  }
});

const { public: config } = useRuntimeConfig();

const emit = defineEmits(['playlist-loaded']);
const emitLoaded = (e: any) => {
  emit('playlist-loaded', e)
}

const topFloatingPosition = ref('74px');

// Change topFloatingPosition if page is scrolled due to changing header height
onMounted(() => document.addEventListener('scroll', () => topFloatingPosition.value = window.scrollY > 0 ? '49px' : '74px', { passive: true }));
</script>

<style>
.jw-wrapper {
  @apply !z-10;
  /* should be lower z-index than header */
}

.jw-float-to-top {
  @apply !top-50;
}

.jw-text {
  @apply text-grey-400 font-khand pl-10 text-12 font-light !important;
}

.jwplayer .jw-display-icon-previous,
.jwplayer .jw-display-icon-next {
  @apply bg-svg-arrow2-white bg-no-repeat bg-center;
  background-size: 17px 17px;
}

.jwplayer .jw-display-icon-previous {
  @apply rotate-90 ml-0;
}

.jwplayer .jw-display-icon-next {
  @apply -rotate-90 mr-0;
}

.jwplayer:not(.jw-aller-compact) .jw-button-container .jw-text-duration {
  @apply !text-grey-300;
}

.jwplayer .jw-title {
  @apply !pt-10 !px-10;
}

.jwplayer .jw-title .jw-title-primary {
  @apply !line-clamp-2 !px-0 !pb-2 !whitespace-normal;
}

.jwplayer:not(.jw-aller-compact) .jw-title .jw-title-primary {
  @apply !text-18 !leading-18 !font-bold !font-sans !p-0 !max-w-[70%];
}

.jwplayer:not(.jw-aller-compact) .jwplayer.jw-state-paused:not(.jw-flag-touch):not(.jw-flag-small-player):not(.jw-flag-casting):not(.jw-flag-play-rejected) .jw-title,
.jwplayer.jw-state-paused:not(.jw-flag-touch):not(.jw-flag-small-player):not(.jw-flag-casting):not(.jw-flag-play-rejected) .jw-display {
  @apply !flex;
}

@media screen and (max-device-width: 480px) {
  .jw-flag-touch.jw-flag-floating .jw-wrapper {
    margin-top: v-bind(topFloatingPosition) !important;
    @apply !transition-all !duration-500;
  }
}
</style>
